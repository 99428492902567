// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
//
// firebase.initializeApp({
//   apiKey: 'AIzaSyDjhGsJEHG5MQOmcPY9Xolfm1j_IQCsxzI',
//   authDomain: 'fast-rune-363213.firebaseapp.com',
//   projectId: 'fast-rune-363213',
//   storageBucket: 'fast-rune-363213.appspot.com',
//   messagingSenderId: '86109140091',
//   appId: '1:86109140091:web:511913f3b3e68ed8341ede',
//   measurementId: 'G-SP1PSQSM5J',
// });
//
// firebase.firestore().settings({ timestampsInSnapshots: true });
//
// export const db = firebase.firestore();
// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
firebase.initializeApp({
  apiKey: 'AIzaSyBJtPp6pIsvj0trVBDYW4x4PH-xR0tb_ec',
  authDomain: 'chrome-empire-372104.firebaseapp.com',
  projectId: 'chrome-empire-372104',
  storageBucket: 'chrome-empire-372104.appspot.com',
  messagingSenderId: '295825445671',
  appId: '1:295825445671:web:69607574bade08840dcf02',
  measurementId: 'G-ZLC3PL3K7W',
  // apiKey: 'AIzaSyDjhGsJEHG5MQOmcPY9Xolfm1j_IQCsxzI',
  // authDomain: 'fast-rune-363213.firebaseapp.com',
  // projectId: 'fast-rune-363213',
  // storageBucket: 'fast-rune-363213.appspot.com',
  // messagingSenderId: '86109140091',
  // appId: '1:86109140091:web:511913f3b3e68ed8341ede',
  // measurementId: 'G-SP1PSQSM5J',
});

// Initialize Firebase
export const db = firebase.firestore();
// const analytics = getAnalytics(app);
